import axios from "axios";

export const baseURL = "https://apib2b.otawix.com";
export const URL2 = "https://apib2b.otawix.com";


export const NODEURL = axios.create({
  baseURL: URL2,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  withCredentials: true,
});

export const HTTP = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  withCredentials: true,
});

export const HTTPJson = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
export const HTTPAccept = axios.create({
  baseURL,
  headers: {
    Accept: "*/*",
  },
  withCredentials: true,
});
export const HTTPForm = axios.create({
  baseURL: URL2,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
  },
  withCredentials: true,
});
